import styled from 'styled-components';

const Th = styled.th`
	cursor: pointer;
	white-space: nowrap;
	user-select: none;

	&.active {
		color: var(--bs-primary);

		&::after {
			display: inline-block;
			font-size: inherit;
			line-height: inherit;
			margin-left: 5px;
		}

		&.asc {
			&::after {
				content: '↑';
			}
		}

		&.desc {
			&::after {
				content: '↓';
			}
		}
	}
`;

export default function ThSortable(props) {
	return (
		<Th onClick={() => props.onClick()}className={(props.direction ?? 'asc') + ' ' + ((props.active ?? false) ? 'active' : '')} style={props.style ?? {}}>{ props.children }</Th>
	);
}