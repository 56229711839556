import { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import styled from 'styled-components';
import numeral from 'numeral';
import { hex2rgb } from 'helpers/generic';

const StyledSpeedometer = styled.div`
    flex-direction: column;
    width: 95%;

    .custom-label {
        display: flex;
        justify-content: space-between;
        color: var(--color);
        font-size: 11px;
        width: 100%;
        padding-left: 20px;
        padding-right: 10px;
        white-space: nowrap;
    }
`;

const Speedometer = (props) => {
    const internalID = (''+Math.random()).replace('.', '');

    let color = props.color ?? null;
    let codigoProducto = props.codigo_producto ?? null;
    let percent = props.percent ? parseInt(props.percent) : 0;
    let warningPercent = props.warningPercent ?? 0;

    let [segmentStops, setSegmentStops] = useState(null);
    let [segmentColors, setSegmentColors] = useState(null);
    let [segmentLabels, setSegmentLabels] = useState(null);
    let [ringWidth, setRingWidth] = useState(5);

    let ready = segmentColors && segmentStops && segmentLabels;

    useEffect(() => {
        // Get colors
        let colorLight = null;
        let colorLight2 = null;
        let colorRGB = hex2rgb(color);
        colorLight = `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.5)`;
        colorLight2 = `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.7)`;

        //--------------------------------------------------------------------------------------//
        //                                   Prepare segments                                   //
        //--------------------------------------------------------------------------------------//
            // Stops
            let segmentStops = [0, 1, 30, 31, 50, 51, 70, 71, 99, 100];
            let warningIdx = segmentStops.findIndex(el => el === warningPercent);
            let warningIdxSameAsPlaceholder = warningIdx !== -1 && segmentStops[warningIdx] % 2 === 0;
            if ( warningIdx === -1 ) {
                let idx = segmentStops.findIndex(el => el > warningPercent);
                if ( idx === -1 ) idx = segmentStops.length; // If cant find a stop greater than warningPercent, add it to the end

                let newSegmentStops = [...[...segmentStops].slice(0, idx), warningPercent, ...[...segmentStops].splice(idx)];
                segmentStops = newSegmentStops;

                // Set the new warning idx
                warningIdx = segmentStops.findIndex(el => el === warningPercent);
            }
            setSegmentStops(segmentStops);

            // Colors
            let segmentColors = [...Array(warningIdx).fill(colorLight2), ...Array(segmentStops.length - warningIdx).fill(colorLight)];
            setSegmentColors(segmentColors);

            // Labels
            let segmentLabels = [
                // Example of needed labels

                // {text: '0', position: 'OUTSIDE'},
                // {text: '', position: 'OUTSIDE'},
                // {text: '', position: 'OUTSIDE'}, // Whis may be the warning label
                // {text: '30', position: 'OUTSIDE'},
                // {text: '', position: 'OUTSIDE'},
                // {text: '50', position: 'OUTSIDE'},
                // {text: '', position: 'OUTSIDE'},
                // {text: '70', position: 'OUTSIDE'},
                // {text: '', position: 'OUTSIDE'},

                // We bypass the 99 label to show it correctly

                // {text: '100', position: 'OUTSIDE'},
            ];
            segmentStops.forEach((el, idx) => {
                if ( idx !== warningIdx && idx !== segmentStops.length - 2 ) segmentLabels.push({text: el % 2 == 0 ? ''+el : '', position: 'OUTSIDE'});
                if ( idx === warningIdx && el !== 99 ) segmentLabels.push({text: warningIdxSameAsPlaceholder ? ''+el : '', position: 'OUTSIDE'}); // If el == 99, doesn't work. We hardcode it to avoid error
            });
            setSegmentLabels(segmentLabels);
        //  --------------------------------------------------------------------------------------
    }, [codigoProducto, warningPercent, color]);

    useEffect(() => {
        if ( !ready ) return;

        let speedometer = document.querySelector('#id' + internalID + ' svg.speedometer');
        let height = speedometer.querySelector('.arc').getBoundingClientRect().height;

        setRingWidth(height / 3);
        
        speedometer.style.height = (height + 30) + 'px';
    }, [ready, internalID]);

    return (
        <StyledSpeedometer id={'id' + internalID}>
            {ready &&
                <ReactSpeedometer
                    fluidWidth={true}
                    forceRender={true}
                    ringWidth={ringWidth}
                    value={percent}
                    minValue={0}
                    maxValue={100}
                    segmentColors={segmentColors}
                    customSegmentStops={segmentStops}
                    customSegmentLabels={segmentLabels}
                    maxSegmentLabels={0}
                    currentValueText={''}
                    needleColor={color}
                    labelFontSize={'8px'}
                />
            }
            
            <div className="custom-label" style={{'--color': color}}>
                <div className="percent">
                    {percent}%	
                </div>
                <div className="capacity">
                    {numeral(props.available).format('0,0[.]0')} / {numeral(props.capacity).format('0,0[.]0')}
                </div>

            </div>
        </StyledSpeedometer>
    );
}

export default Speedometer;