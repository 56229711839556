import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { jsonToArray, loader, delay } from 'helpers/generic';
import OilRow from './OilRow';
import Modal from './Modal';
import { default as ProbesService } from 'services/Probes';

const MAX_ATTEMPT = 2;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #fffaf6;
    user-select: none;
    cursor: pointer;

    .station-details-header {
        text-transform: uppercase;
        color: white;
        background: var(--plenoil-orange);
        width: 100%;
        padding-left: 20px;
    }

    .error {
        padding: 20px;
        color: var(--plenoil-red);

        button {
            padding: 0 2px;
            display: inline-flex;
            background: var(--plenoil-red);
            color: white;
        }
    }
`;

const StationDetails = (props) => {
    let station = props.station;

    let availableSlot = useCallback(() => props.availableSlot(), []);
    let getSlot = useCallback(() => props.getSlot(), []);
    let freeSlot = useCallback(() => props.freeSlot(), []);

    let [forceReload, setForceReload] = useState(false);
    let [loading, setLoading] = useState(false);
    let [modalOpened, setModalOpened] = useState(false);
    let [attempt, setAttempt] = useState(1);
    let [error, setError] = useState(null);
    let [showRetryButton, setShowRetryButton] = useState(false);
    let [tanks, setTanks] = useState(null);
    let [warningPercent, setWarningPercent] = useState(0);

    useEffect(() => {
        let slot = null;

        let getProbesData = async () => {
            setLoading(true);

            slot = availableSlot();
            while ( !slot ) {
                await delay(1000);
                if ( slot === -1 ) return; // Fix when unmount component
                slot = availableSlot();
            }

            getSlot();

            let result = await ProbesService.get(station.octan_id, attempt);
            if ( result.error ) {
                if ( attempt < MAX_ATTEMPT ) {
                    setAttempt(attempt + 1);
                }
                if ( attempt === 1 ) {
                    setError('No es posible conectar a la sonda. Se está intentando de nuevo por la IP backup. ('+result.error.substr(0, 50)+'...)');
                }
                if ( attempt === MAX_ATTEMPT ) {
                    setError('No es posible conectar a la sonda por ninguna de sus IP. ('+result.error.substr(0, 50)+'...)');
                    setShowRetryButton(true);
                }
            } else {
                setWarningPercent(result.warning_percent);
                setTanks(result.tanks);
                setError(null);
            }
            
            freeSlot();

            setLoading(false);
        }
        getProbesData();

        return () => {
            slot = -1; // Fix to prevent infinite loop
            ProbesService.cancel();
        }
    }, [station.id, attempt, availableSlot, getSlot, freeSlot, forceReload]);

    const openModal = () => {
        setModalOpened(true);
    }

    // Prepare sum of oils
    let oils = {};
    tanks?.forEach(tank => {
        if ( !oils[tank.codigo_producto] ) {
            oils[tank.codigo_producto] = {
                codigo_producto: tank.codigo_producto,
                name: (() => {
                    let name = 'no-contemplado';
                    if ( tank.codigo_producto === '1' ) name = 'DIESEL';
                    if ( tank.codigo_producto === '5' ) name = 'SP95';
                    if ( tank.codigo_producto === '7' ) name = 'ADBLUE';
                    return name;
                })(),
                capacity: 0,
                capacity_octan: 0,
                difference: 0,
                available: 0,
                temperature: tanks.filter(el => el.codigo_producto === tank.codigo_producto).reduce((acc, el) => acc + el.temperature, 0) / tanks.filter(el => el.codigo_producto === tank.codigo_producto).length,
                percent: tanks.filter(el => el.codigo_producto === tank.codigo_producto).reduce((acc, el) => acc + el.percent, 0) / tanks.filter(el => el.codigo_producto === tank.codigo_producto).length,
                color: (() => {
                    let color = null;
                    if ( tank.codigo_producto === "1" ) {
                        color = getComputedStyle(document.documentElement).getPropertyValue('--plenoil-black');
                    }
                    if ( tank.codigo_producto === "5" ) {
                        color = getComputedStyle(document.documentElement).getPropertyValue('--plenoil-green');
                    }
                    if ( tank.codigo_producto === "7" ) {
                        color = getComputedStyle(document.documentElement).getPropertyValue('--plenoil-blue');
                    }
                    return color;
                })()
            };
        }
        
        oils[tank.codigo_producto].capacity += tank.capacity;
        oils[tank.codigo_producto].capacity_octan += tank.capacity_octan;
        oils[tank.codigo_producto].difference += tank.difference;
        oils[tank.codigo_producto].available += tank.available;
        oils[tank.codigo_producto].h2o_warning = (tank.alturaH2O > 0) ?? false;
    });

    return (
        <>
            <StyledWrapper onClick={() => !loading ? openModal() : null}>
                <div className="station-details-header">
                    {station.name}
                </div>
                {loading &&
                    loader
                }
                {error &&
                    <div className="error">
                        {error}
                        {showRetryButton &&
                            <div className="text-center mt-2">
                                <button 
                                    className="btn btn-sm" 
                                    onClick={() => {
                                        setError(null);
                                        setAttempt(1);
                                        setShowRetryButton(false);
                                    }}
                                >
                                    reintentar
                                </button>
                            </div>
                        }
                    </div>
                }
                {Object.keys(oils).length > 0 && jsonToArray(oils).map(el => (
                    <OilRow key={el.key} oil={el.value} warningPercent={warningPercent} />
                ))}
            </StyledWrapper>
            {modalOpened && 
                <Modal 
                    station={station} 
                    tanks={tanks}
                    warningPercent={warningPercent}
                    totals={oils}
                    close={() => setModalOpened(false)}
                    reload={() => setForceReload(Math.random())}
                    loading={loading}
                />
            }
        </>
    );
}

export default StationDetails;